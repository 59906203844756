import { FC, ReactElement, useEffect } from 'react'

import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { useBoolean, useLocalStorage } from 'usehooks-ts'

import { useAuth } from 'src/Providers'

import { DragAndDropFolder } from './DragAndDropFolderList'

interface FolderProps {
  folder: DragAndDropFolder
  children?: ReactElement
  DragHandle?: JSX.Element
  isSubFolder?: boolean
  forceOpen?: boolean
  FolderTitleActions?: JSX.Element
}

const Folder: FC<FolderProps> = ({
  folder,
  children,
  DragHandle = null,
  isSubFolder = false,
  forceOpen = false,
  FolderTitleActions = null,
}) => {
  const folderIsSubFolder = DragHandle === null || isSubFolder

  const { currentUser, hasRole } = useAuth()

  const isEditorOrAbove = hasRole(['EDITOR', 'ADMIN', 'OWNER'])

  // Keep Accordions Open on state change or refetch
  const [openFolderIds, setOpenFolderIds] = useLocalStorage<number[]>(
    `folder-expanded-${currentUser.parentData.id}-${currentUser.userData.id}`,
    [],
  )

  const handleExpand = (id) => {
    if (!openFolderIds.includes(id)) {
      setOpenFolderIds([...openFolderIds, id])
    }
  }

  const handleCollapse = (id) => {
    setOpenFolderIds([...openFolderIds.filter((savedId) => savedId !== id)])
  }

  const FolderTitle = ({ DragHandle, title, open }) => {
    return (
      <div
        className={`flex w-full items-center ${folderIsSubFolder && 'pl-3'}`}
        data-intercom-target={`folder-title-${open ? 'open' : 'collapsed'}`}
      >
        {(isEditorOrAbove && DragHandle) ?? <span className="w-[26px]"></span>}
        <ChevronDownIcon
          className={`w-4 h-4 ${open ? 'rotate-0' : '-rotate-90'} `}
        />
        <h3
          className="line-clamp-1 pl-2 flex-1 text-gray-600"
          data-testid={`folder-title-${open ? 'open' : 'collapsed'}-${title}`}
        >
          {title}
        </h3>
      </div>
    )
  }

  const folderIsExpanded = useBoolean(openFolderIds.includes(folder.id))

  useEffect(() => {
    if (folderIsExpanded.value) {
      handleExpand(folder.id)
    } else {
      handleCollapse(folder.id)
    }
  }, [folderIsExpanded.value])

  useEffect(() => {
    forceOpen && folderIsExpanded.setTrue()

    if (!forceOpen) {
      if (openFolderIds.includes(folder.id)) {
        folderIsExpanded.setTrue()
      } else {
        folderIsExpanded.setFalse()
      }
    }
  }, [forceOpen])

  return (
    <div>
      <Accordion
        disableGutters
        elevation={0}
        expanded={folderIsExpanded.value}
        onClick={(event) => {
          event.stopPropagation()
          folderIsExpanded.toggle()
        }}
        className={`!overflow-hidden ${
          folderIsSubFolder
            ? '!border-0 !border-b bg-white !rounded-none'
            : '!border bg-gray-100 !rounded'
        }`}
      >
        <AccordionSummary
          sx={{
            '& .MuiAccordionSummary-content': {
              margin: 0,
            },
          }}
          className={`p-0 m-0 pl-2 pr-4 flex items-center gap-2 ${
            folderIsSubFolder && '!rounded-0'
          }`}
          expandIcon={null}
          aria-controls="folder-content"
          id="folder-header"
        >
          <div className="flex gap-5 items-center justify-between w-full">
            <FolderTitle
              DragHandle={DragHandle}
              title={folder.name}
              open={folderIsExpanded.value}
            />
            {FolderTitleActions}
          </div>
        </AccordionSummary>
        <AccordionDetails
          onClick={(event) => event.stopPropagation()}
          className={`p-0 border-t overflow-hidden ${
            folderIsSubFolder && 'bg-white'
          }`}
        >
          <div className="overflow-hidden">
            {folderIsExpanded.value && children}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default Folder
