export const reorder = <T,>(
  list: T[],
  startIndex: number,
  endIndex: number,
) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const getItemStyle = (
  _isDragging: boolean,
  draggableStyle: any,
  grid: number = 0,
) => {
  const axisLockY = draggableStyle?.transform
    ? 'translate(0px' +
      draggableStyle.transform.slice(
        draggableStyle.transform.indexOf(','),
        draggableStyle.transform.length,
      )
    : null

  return {
    ...draggableStyle,
    userSelect: 'none',
    margin: `0 0 ${grid}px 0`,
    transform: axisLockY,
  }
}
