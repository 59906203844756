import DoubleVerticalDotsIcon from 'src/components/SettingsLayoutSection/DoubleVerticalDotsIcon'

export const DragHandle = ({ dragHandleProps, disableDragAndDrop }) => {
  return (
    <div
      className={`drag-handle ${
        disableDragAndDrop ? '!opacity-20' : 'cursor-grab'
      }`}
      {...(dragHandleProps && !disableDragAndDrop && dragHandleProps)}
    >
      <DoubleVerticalDotsIcon />
    </div>
  )
}
